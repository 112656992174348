@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1080deg);
  }
}

$radius: 90%;

@import url(//fonts.googleapis.com/css?family=Bungee);

.container {
  position: fixed;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
    filter: hue-rotate(360deg);
  }
  50% {
    transform: rotate(360deg);
    filter: hue-rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    filter: hue-rotate(0deg);
  }
}
@keyframes logoan {
  0% {
    filter: hue-rotate(360deg);
  }
  /* 50% {
    filter: hue-rotate(0deg);
  } */
  100% {
    filter: hue-rotate(0deg);
  }
}
